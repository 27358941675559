import axiosIns from '@/libs/axios'

export default {
  getAllCampuses: (() => axiosIns.get('accounting/paid-report/campuses')),
  getAllSchools: (() => axiosIns.get('accounting/paid-report/schools')),
  getAllClasses: (() => axiosIns.get('accounting/paid-report/classes')),
  getAllSession: (classId => axiosIns.get(`accounting/paid-report/academic-session/${classId}`)),
  getClassesBySchoolId: (id => axiosIns.get(`accounting/paid-report/classes-by-school-id/${id}`)),
  getAllSection: ((classId, shiftId, sessionId) => axiosIns.get(`accounting/paid-report/academic-section/${classId}/${shiftId}/${sessionId}`)),
  getAllBanks: (() => axiosIns.get('accounting/paid-report/banks')),
  getPaidReport: (payload => axiosIns.post('accounting/paid-report', payload)),
  getHeadWiseReport: (payload => axiosIns.post('accounting/head-wise-summaries', payload)),
}
